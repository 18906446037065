"use client";

import Landing from "@/components/home/landing/Landing";
import Body from "@/components/home/body/Body";
import Roadmap from "@/components/home/roadmap/Roadmap";

export default function Home() {

  
  return (
    <div className="home-container">
      <Landing />
      <Body />
      <Roadmap />
    </div>
  );
}