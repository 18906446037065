"use client";

import { Row, Col } from 'antd/lib';
// import { Parallax } from 'rc-scroll-anim';
import CheckedIcon from '../../../public/home/checked-icon.svg';

// import Image from "next/image";

import './Roadmap.styles.scss';

export default function Roadmap() {

  
  return (
    <div className="roadmap-container">
      <Row className="roadmap-row">
        <Col
          xs={{ span: 10 }}
          md={{ span: 10 }}
          sm={{ span: 10 }}
          lg={{ span: 10 }}
          className="ant-col-img-roadmap"  
        > 
        </Col>
        <Col
          xs={{ span: 14 }}
          md={{ span: 14 }}
          sm={{ span: 14 }}
          lg={{ span: 14 }}
          className="ant-col-title-roadmap"  
        >
          <Row>
            <h3 className="roadmap-row-title">
              ROADMAP
            </h3>
            {/* <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0, 0.2], always: false }}
              style={{ transform: 'translateX(-100px)', opacity: 0 }}
            >
              <h1 className="roadmap-row-subtitle">
                WE ARE GOING TO BUILD SOLAR AND HYDRO POWER FARMS.
              </h1>
            </Parallax> */}
          </Row>
          <Row className='roadmap-row-intro'>
            <h3>
              Our mission is to empower communities through renewable energy. We are building solar and hydro power farms, harnessing the power of the sun and water to create a sustainable future. By combining blockchain technology with real-world renewable assets, we are making green energy investments accessible to everyone. Along the way, we are growing an incredible community of forward-thinking investors, providing exclusive benefits, and pioneering a new frontier in sustainable energy.
            </h3>
          </Row>
          <Row>
            <div className='roadmap-list-container'>
              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Ticket Sale & Ownership Attribution
                  </h2>
                  <h5>
                    On [Launch Date], we will launch the Clima Ticket sale, inviting individuals to invest in renewable energy projects. By purchasing Clima Tickets, investors join an exclusive pool that collectively owns a stake in a solar or hydro farm. Ownership is secured transparently on the blockchain, making green energy accessible for all.
                  </h5>
                </Col>
              </div>
              
              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Land Acquisition
                  </h2>
                  <h5>
                    Once we reach our funding goal through Clima Ticket sales, we will acquire the land for our renewable farm. Our team is already scouting locations that promise maximum energy output and community impact.
                  </h5>
                </Col>
              </div>

              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Farm Construction & Installation
                  </h2>
                  <h5>
                    With the land secured, we will begin building our renewable farm. We will partner with top engineering teams to ensure that the solar panels and hydro equipment are installed to the highest standards, creating an efficient, sustainable power source.
                  </h5>
                </Col>
              </div>

              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Investor Compliance & KYC 
                  </h2>
                  <h5>
                    After construction, we will carry out Know Your Customer (KYC) processes to ensure compliance with regulatory requirements. This ensures that all Clima Ticket holders are legally registered as stakeholders in the farm, solidifying their ownership.
                  </h5>
                </Col>
              </div>

              
              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Building the DeFi Platform 
                  </h2>
                  <h5>
                    We are not stopping with one farm. Our vision is to expand renewable investments for everyone. We will develop a decentralized finance (DeFi) platform to help launch and scale similar projects, offering more opportunities for community-backed energy initiatives.
                  </h5>
                </Col>
              </div>

              <div className='roadmap-list-item-wrapper'>
                <Col
                  xs={{ span: 3 }}
                  md={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                  className="ant-col-list-roadmap-icon"  
                >
                  <div className='list-roadmap-icon'>
                    <CheckedIcon className='list-checked-icon' />
                  </div>
                </Col>
                <Col
                  xs={{ span: 21 }}
                  md={{ span: 21 }}
                  sm={{ span: 21 }}
                  lg={{ span: 21 }}
                  className="ant-col-list-roadmap-text"  
                >
                  <h2>
                    Revenue & Dividends 
                  </h2>
                  <h5>
                    Once the farm starts generating power and revenue, it is time to share the success. Profits will be distributed to Clima Ticket holders, providing returns to our community of investors based on the equity they own.
                  </h5>
                </Col>
              </div>

            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}