"use client";

import { Row, Col, Input, Space, Button, message } from 'antd/lib';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import Image from "next/image";
import SendArrowIcon from '../../../public/home/send-arrow-icon.svg';

import './Landing.styles.scss';

export default function Landing() {

   const [newsletterEmail, setNewsletterEmail] = useState('');
   const { address, isConnected } = useAccount();

   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewsletterEmail(e.target.value);
   };

   const handleSubscribe = async () => {
      if (!newsletterEmail) {
         message.error('Please enter a valid email address');
         return;
      }

      if (!isConnected || !address) {
         message.error('Please connect your wallet to subscribe.');
         return;
      }

      try {
         const response = await fetch('/api/newsletter', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({ email: newsletterEmail, walletAddress: address }),
         });

         if (response.ok) {
         message.success('You have successfully subscribed to the newsletter!');
         } else {
         const errorData = await response.json();
         message.error(`Subscription failed: ${errorData.error}`);
         }
      } catch (error) {
         console.error('Failed to subscribe:', error);
         message.error('An error occurred while subscribing. Please try again.');
      }
   };

  
  return (
    <div className="landing-container">
      <Row className="landing-row">
         <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            lg={{ span: 24 }}
            className="ant-col-title"  
         >
            <h1 className="landing-title">
               Build and Sell Renewable Energy
            </h1>
            <h2 className="landing-subtitle">
               Profit from Sunshine and Rivers 
            </h2>
         </Col>
      </Row>
      
      <Row className="landing-row-newsletter">
         <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            className="ant-col-newsletter"  
           >
            <div>Stay informed on the latest news provided by Clima</div>
            <Space direction="vertical" size="middle" className="landing-newsletter-btn">
               <Space.Compact style={{ width: '100%' }}>
                  <Input
                        placeholder="Join our newsletter"
                        value={newsletterEmail}
                        onChange={handleEmailChange}
                  />
                  <Button
                     type="primary"
                     className="landing-newsletter-send"
                     onClick={handleSubscribe}
                  >
                     <SendArrowIcon />
                  </Button>
               </Space.Compact>
            </Space>
         </Col>
         <Col
            xs={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            lg={{ span: 12 }}
            className="ant-col-newsletter-img" 
         >
            <Image
               src="/home/landing-solar.png"
               width={290}
               height={270}
               alt="illustration solar panels farm"
               className="landing-solar-img"
            />
            <Image
               src="/home/landing-hydro.png"
               width={290}
               height={270}
               alt="illustration hydro power farm"
               className="landing-hydro-img"
            />
         </Col>  
      </Row>  
    </div>
  );
}